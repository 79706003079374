import React from "react";
import classNames from "classnames";
import { Slideshow } from "@molecules";
import { Container, Text, Button } from "@atoms";

const FullHero = ({
  button,
  descriptor,
  fullWidth,
  heading,
  images,
  secondaryLink,
}) => {
  const hasWindow = typeof window !== "undefined";

  const isAnchor = url => url.charAt(0) === "#";

  const scrollTo = target => {
    if (hasWindow) {
      document.getElementById(target)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <section
      className={classNames(
        "relative flex min-h-[60vh] flex-col bg-black/10 pt-48 md:min-h-[80vh]",
        {
          "justify-end": fullWidth,
        }
      )}
    >
      <Container className="relative z-10 flex items-start justify-between gap-8">
        <Text variant="heading" className="max-w-[10ch] flex-grow text-white">
          {heading}
        </Text>
        {descriptor && (
          <Text variant="xl" className="mb-1">
            {descriptor}
          </Text>
        )}
        {!!button?.url && (
          <Button
            to={isAnchor(button.url) ? null : button.url}
            onClick={() =>
              isAnchor(button.url) ? scrollTo(button.url.split("#")[1]) : {}
            }
            rotate={fullWidth}
            color="white"
            className="hidden md:top-2.5 md:flex"
          >
            {button.text}
          </Button>
        )}
      </Container>
      {/* background image */}
      <div className="absolute inset-0 z-0 bg-black/50">
        <Slideshow slides={images} className="mix-blend-overlay" />
      </div>
    </section>
  );
};

export default FullHero;

FullHero.defaultProps = {
  fullWidth: true,
};
